.App-header {
  min-height: unset;
  color: inherit;
}


header, footer {
  background-color: #374153;
  text-align: center;
  color: inherit;
  padding: 10px 0;
}

header {
  margin-bottom: 60px;
  font-size: 28px;
}

footer {
  margin-top: 50px;
  font-size: 14px;
  border-top: 0.5px solid rgba(140, 140, 140, 0.4)
}

.card {
  margin: 10px 0;
}

a, a:hover {
  color: #282c34;
  text-decoration: none;
}

video {
  width: 100%;
  height: inherit;
}

img {
  max-width: 60%;
  object-fit: cover;
  object-position: center top;
}

p {
  margin-bottom: 5px;
  font-size: 16px;
}

.content {
  transition: 300ms;
}

.side-nav.hide {
  transform: translateX(-100%) !important;
}
 
 
@keyframes swing {
  0%, 30%, 50%, 70%, 100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
.pro-sidebar {
  color: inherit;
  height: calc(100% - 14px);
  width: 296px;
  min-width: 296px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #1d1d1d;
  height: 100%;
  position: relative;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout:first-child {
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
   
  overflow:auto;
  overflow-x:hidden;
  height:calc(100% - 0px);
}
 
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
 
.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}
.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
.pro-sidebar.rtl {
  text-align: right;
  direction: rtl;
}
 
@media (max-width: 480px) {
  
  .pro-sidebar.xs {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xs.collapsed {
    left: -80px;
  }
  .pro-sidebar.xs.toggled {
    left: 0;
  }
  .pro-sidebar.xs.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.sm.collapsed {
    left: -80px;
  }
  .pro-sidebar.sm.toggled {
    left: 0;
  }
  .pro-sidebar.sm.toggled .overlay {
    display: block;
  }
  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0;
  }
}
 @media (max-width: 768px) {
  .pro-sidebar.md {
    position: relative;
    left: inherit;
  }
  .pro-sidebar.md.collapsed {
    left: -80px;
  }
  .pro-sidebar.md.toggled {
    left: 0;
  }
  .pro-sidebar.md.toggled .overlay {
    display: block;
  }
  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0;
  }
} 
@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.lg.collapsed {
    left: -80px;
  }
  .pro-sidebar.lg.toggled {
    left: 0;
  }
  .pro-sidebar.lg.toggled .overlay {
    display: block;
  }
  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xl.collapsed {
    left: -80px;
  }
  .pro-sidebar.xl.toggled {
    left: 0;
  }
  .pro-sidebar.xl.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: "";
  display: none;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  margin-right: 0px;
  position: relative;
   
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
}

.pro-sidebar .pro-menu {
  padding-top: 0px;
  padding-bottom: 0px;
  position:relative;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pro-sidebar .pro-menu a {
  text-decoration: none;
  color:inherit

}
.pro-sidebar .pro-menu a:before {
  content: "";
  display:none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  color:inherit
}
.pro-sidebar .pro-menu a:hover {
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
  margin:0px 0px;
  padding: 0px 0px;
  border-top: 0px solid #bcbcbc;
  border-bottom: 0.5px solid rgb(140 140 140 / 42%);
   
}
.pro-sidebar .pro-menu .pro-menu-item:only-child {
  font-size: 15px;
  margin:0px 0px;
  padding: 10px 0px;
  border-top: 0px solid #bcbcbc;
  border-bottom: 0.5px solid rgb(140 140 140 / 42%);
   
}
.pro-sidebar .pro-menu .pro-menu-item:last-child {
  font-size: 15px;
  margin:0px 0px;
  padding: 0px 0px;
  border-top: 0px solid #bcbcbc;
  
}
.pro-sidebar .pro-menu .pro-menu-item:first-child {
  font-size: 15px;
  margin:0px 0px;
  padding-bottom:0px;
  
  border-top: 0.5px solid rgb(140 140 140 / 42%);
  
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  display: inline-block;
  position: relative;
  right: 35px;
  opacity: 1;
  transition: opacity 0.2s;
  color: inherit;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 5px;
  cursor: pointer;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: inherit;
}



.searchbar {
  color: #adadad;
  pointer-events:auto;
  height: auto;
  width: 284px;
  min-width: 254px;
  text-align: left;
  left: 1px;
  top:50px;
  transition: width, left, right, 0.3s;
  position: inherit;
  z-index: 1009;
  max-height:400px;
  overflow:auto;
}
.searchbar > .pro-sidebar-inner {
  pointer-events:auto;
  background: transparent;
  height: auto;
  max-height:400px;
  position: relative;
  z-index: 101;
}
.searchbar .pro-menu a {
  text-decoration: none;
  color: #000;
}
.searchbar  .pro-menu a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.searchbar  .pro-menu a:hover {
  color: #000;
}
.searchbar .pro-menu .pro-menu-item {
  font-size: 15px;
  border-bottom:1px solid #ccc;
  padding:10px 0px ;
}
.searchbar .pro-menu .pro-menu-item.active {
  color: #000;
}
.searchbar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 5px;
  cursor: pointer;
}
.searchbar .pro-menu a {
  outline: none;
  color: #000;
}
.searchbar .pro-menu a:focus {
  outline: none;
  color: #000;
}

.mobile-searchbar {
  color: #adadad;
  pointer-events:auto;
  height: auto;
  width: 100%;
  min-width: 254px;
  text-align: left;
  left: 1px;
  top:54px;
  transition: width, left, right, 0.3s;
  position: inherit;
  z-index: 1009;
 
  overflow:auto;
}
.mobile-searchbar > .pro-sidebar-inner {
  pointer-events:auto;
  background: transparent;
  height: auto;
  max-height:400px;
  position: relative;
  z-index: 101;
}
.mobile-searchbar .pro-menu a {
  text-decoration: none;
  color: #000;
}
.mobile-searchbar  .pro-menu a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.mobile-searchbar  .pro-menu a:hover {
  color: #000;
}
.mobile-searchbar .pro-menu .pro-menu-item {
  font-size: 15px;
  border-bottom:1px solid #ccc;
  padding:0px 0px ;
}
.mobile-searchbar .pro-menu .pro-menu-item.active {
  color: #000;
}
.mobile-searchbar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 5px;
  cursor: pointer;
}
.mobile-searchbar .pro-menu a {
  outline: none;
  color: #000;
}
.mobile-searchbar .pro-menu a:focus {
  outline: none;
  color: #000;
}

.mobile-searchbar > .pro-sidebar-inner > .pro-sidebar-layout:first-child {
  height: calc(100% - 30px) !important;
  position: relative;
  width: 100%;
  background-color: '#fff';
  padding-bottom: 0px;
  top: 0px;
  left: 0px;
  /* max-width: 400px; */
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 10px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  color:inherit
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
  background: #adadad;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #adadad;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
  background: transparent !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 0px 0px 0px 0px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  display: none;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: rgba(255, 255, 255, 0.05);
}
.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 0;
}
.pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 4px;
}
.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 50%;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
  opacity: 0;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
  background-color: rgba(29, 29, 29, 0.95);
  z-index: 111;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
  position: relative;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
  pointer-events: none;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-left: 10px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: rgba(29, 29, 29, 0.95);
  padding-left: 20px;
  border-radius: 4px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
  transition: visibility, transform 0.3s;
  visibility: visible;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 0px 30px 0px 5px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0px 0px 0px 5px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  transform: rotate(135deg);
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 20px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 15px 8px 30px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-right: 10px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  padding-left: 0;
  padding-right: 20px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px;
}

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^=left] > .popper-arrow {
  right: 0;
  border-right: 7px solid rgba(29, 29, 29, 0.95);
}

.popper-element[data-popper-placement^=right] > .popper-arrow {
  left: 0;
  border-left: 7px solid rgba(29, 29, 29, 0.95);
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

body {
  margin: 0;
  height: 100vh;
  color: #000;
  font-family: 'IBM Plex Sans','Poppins', sans-serif
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
}
.app .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}
.app .sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .sidebar-btn-wrapper .sidebar-btn {
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #333;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 5px;
  font-size: 13px;
}
.app .sidebar-btn-wrapper .sidebar-btn:hover {
  color: #d8d8d8;
}
.app .collapsed .sidebar-btn {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 40px;
  padding: 0;
}
.app main {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.app main header {
  margin-bottom: 40px;
  border-bottom: 1px solid #efefef;
}
.app main header h1 {
  display: flex;
  align-items: center;
  transform: translateX(-20px);
}
.app main footer {
  margin-top: auto;
  color: #888;
  text-align: center;
}
.app main footer a {
  text-decoration: none;
  color: #888;
}
.app main footer a:hover {
  text-decoration: underline;
}
.app .social-bagdes {
  margin-top: 10px;
}
.app .social-bagdes img {
  margin: 5px;
}
.app .block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: #545454;
}
.app .block > span {
  margin-left: 10px;
}
.app.rtl {
  direction: rtl;
  text-align: right;
}
.app.rtl header h1 {
  transform: translateX(20px);
}
.app.rtl .block {
  direction: rtl;
}
.app.rtl .block > span {
  margin-left: 0;
  margin-right: 10px;
}
.app.rtl .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 0;
  margin-right: 5px;
}
@media (max-width: 767.98px) {
  .app {
    overflow-x: hidden;
  }
  .app header h1 {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .app .btn-toggle {
    display: flex;
  }
  .app.rtl .btn-toggle {
    margin-left: auto;
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
}
.badge.red {
  color: #ffffff;
  background: #d63030;
}
.badge.gray {
  color: #ffffff;
  background: #777777;
}
.badge.yellow {
  color: #000000;
  background: #ffd331;
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

 

#balloon-container {
  height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width:50%;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  float:right;
  right:20px;
  pointer-events: none;
  zoom: 50%;
}

.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #FDFD96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:after {
    content: "▲";
    text-align: center;
    display: block;
    position: absolute;
    color: inherit;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
    animation-iteration-count: 1
}

@keyframes float {
  from {transform: translateY(100vh);
  opacity: 1;}
  to {transform: translateY(-300vh);
  opacity: 0;}
}
#searchField{
  padding:9px 10px;
  border:none;
  line-height:1.9em;
  background-color: inherit;
  border-bottom: 1px solid rgba(140, 140, 140, 0.42);
  color:inherit;
  width:100%
   
}
:focus-visible {
  outline: none;
}
b, strong {
  font-weight: bold;
}

.searchbar > .pro-sidebar-inner > .pro-sidebar-layout:first-child {
  height: calc(100% - 17px) !important;
  position:fixed;
  background-color:'#fff';
  padding-bottom:40px;
  top:55px;
  left:0px;
  max-width:400px;
  
}

.w-100 {
  width: 100%!important; 
  height:550px
}

 
a {
  color: inherit ;
  text-decoration: none !important;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color:inherit;
  color:inherit
}


span.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

span.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.searchbar .pro-menu .pro-menu-item {
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  color: #333;
}
.video-js .vjs-tech {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-js .vjs-big-play-button  {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left:-50px;
  margin-top:-30px;
   
}
.video-js {
  display: block; 
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  width: 100%;
  height: inherit;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
}
 
.highlight{
   color:#c9baf4; 
}
.text-highlight{
  color:#c9baf4; 
}
.pro-sidebar {
  padding-bottom:0px;
}



.ytp-ce-covering-overlay{
  display:none !important;
    z-index: 0  !important;
}
 .badge-dark{
   background-color:'#ccc'
 }
 .badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
 
.btn-wyre{
  color: #fff;
  background-color: #66048b !important;
  border-color: #59007b !important; 
}
.btn-wyre:hover{
  color: #fff;
  background-color: #9135b3 !important;
  border-color: #490064 !important;
}
.bg-dark {
  background-color: #44594e !important;
  color: #fff !important;
}
.badge {
  color: #fff;
  background-color: #2f243a ;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545  ;
}
.badge-success {
  color: #fff;
  background-color: #44594e !important;
}
.btn-like{
  color: #fff;
  background-color: #2f243a !important;
}
.btn-default:hover {
  color: #fff;
    background: rgba(0,0,0,0.4) !important;
}

.flash {
  background-color: #7e0909;
  display: inline-block;
    padding: 5px 7px;
    
    font-weight: bold;
   
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    
    border-radius: 0.25rem;
    border: none;
  
}
@keyframes glowing {
  0% {
    color: #fff;
    background-color: #7e0909;
     
  }
  50% {
    color: #fff;
    background-color: #dc3545;
     
  }
  100% {
    color: #fff;
    background-color: #7e0909;
    
  }
}
.flash {
  animation: glowing 2500ms infinite;
}