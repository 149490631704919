.section-gap {
  min-height: 200px;
}

.section-small-gap {
  min-height: 100px;
}

@media (min-width: 769px) {
  .section-gap {
    min-height: 100px;
  }
}
