body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}
.App-header {
  min-height: 100vh;
  color: white;
  overflow: auto; 
}

.ytp-expand-pause-overlay .ytp-pause-overlay {
  background: rgba(23,23,23,0.9);
  padding: 12px 16px 16px;
  right: 0;
  opacity:0
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.video-responsive-no-click iframe {
  left: 0;
  top: 0;
  height: auto;
  width: 100%;
  pointer-events:none;
  position: relative;
}
.video-responsive-no-click iframe .ytp-show-cards-title{
  display:none;
}

/* devanagari */
/*@font-face {*/
/*  font-family: 'Poppins';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  src: url('/fonts/pxiEyp8kv8JHgFVrJJbecmNE.woff2') format('woff2');*/
/*  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;*/
/*}*/
/*!* latin-ext *!*/
/*@font-face {*/
/*  font-family: 'Poppins';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  src: url('/fonts/pxiEyp8kv8JHgFVrJJnecmNE.woff2') format('woff2');*/
/*  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;*/
/*}*/
/*!* latin *!*/
/*@font-face {*/
/*  font-family: 'Poppins';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  src: url('/fonts/pxiEyp8kv8JHgFVrJJfecg.woff2') format('woff2');*/
/*  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;*/
/*}*/

.u-userLauncherColor:not([disabled]) {
  background-color: #65ebd8 !important;  color: #1F6057 !important;  fill: #1F6057 !important;
}

/* 
@tailwind base;
@tailwind components;
@tailwind utilities; */
 

@layer base {
  html {
    @apply font-body;
    font-size: 1rem;
    line-height: 1.7;
    font-weight: 300;
    word-break: break-word;
  }

  @media screen and (min-width: 769px) {
    html {
      font-size: 1.125rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mt-0 mb-4 font-display font-normal leading-normal uppercase;
  }

  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-base;
  }
  h6 {
    @apply text-sm;
  }

  p {
    @apply mt-0 mb-4;
  }
}
